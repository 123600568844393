import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Newsletter from "./components/Footer/Newsletter";
import Footer from "./components/Footer/Footer";
import AllRouting from "./AllRouting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "./contexts/UserContext";
import CartContext from "./contexts/CartContext";
import useFacebookPixel from "./utils/useFacebookPixel";

const App = () => {
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState([]);
  const facebookPixel = useFacebookPixel();

  const saveCartToLocalStorage = (cartData) => {
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  // // Initialize Meta Pixel
  // useEffect(() => {
  //   const pixelId = "7028933373786534";

  //   const loadPixel = () => {
  //     if (!window.fbq) {
  //       window.fbq = function () {
  //         window.fbq.callMethod
  //           ? window.fbq.callMethod(...arguments)
  //           : window.fbq.queue.push(arguments);
  //       };
  //       window.fbq.queue = [];
  //       window.fbq.version = "2.0";
  //       window.fbq.loaded = true;

  //       const script = document.createElement("script");
  //       script.async = true;
  //       script.src = "https://connect.facebook.net/en_US/fbevents.js";
  //       document.head.appendChild(script);
  //     }

  //     window.fbq("init", pixelId);
  //     window.fbq("track", "PageView");
  //   };

  //   loadPixel();
  // }, []);


  useEffect(() => {
    const token = localStorage.getItem("userID");
    setUser(token);
  }, []);

  useEffect(() => {
    if (cart.length === 0) {
      const storedCart = localStorage.getItem("cart");
      if (storedCart) {
        const parsedCart = JSON.parse(storedCart);
        setCart(parsedCart);
      }
    }
  }, []);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (product, quantity, selectedVariant) => {
    const updatedCart = [...cart];
    const productIndex = updatedCart.findIndex(
      (item) =>
        item.product.product_id === product.product_id &&
        item.variant.p_v_id === selectedVariant.p_v_id
    );
    if (productIndex === -1) {
      updatedCart.push({
        product: product,
        quantity: quantity,
        variant: selectedVariant,
      });
      toast.success("Product successfully added to cart");

      if (facebookPixel) {
        facebookPixel.trackEvent(
          'AddToCart',
          {
            content_ids: [product.product_id],
            content_type: 'product',
          }
        );
      }

    } else {
      updatedCart[productIndex].quantity += quantity;
      toast.success("Cart updated successfully");
    }
    setCart(updatedCart);
  };

  const updateCart = (type, productId, variantId) => {
    const updatedCart = [...cart];
    const productIndex = updatedCart.findIndex(
      (item) =>
        item.product.product_id === productId ||
        item.variant.p_v_id === variantId
    );

    if (type === "increase") {
      updatedCart[productIndex].quantity += 1;
      setCart(updatedCart);
    }

    if (type === "decrease") {
      updatedCart[productIndex].quantity -= 1;
      setCart(updatedCart);
    }
  };

  const removeFromCart = (productId, variantId) => {
    const newCart = cart.filter(
      (item) =>
        item.product.product_id !== productId ||
        item.variant.p_v_id !== variantId
    );

    setCart(newCart);
    saveCartToLocalStorage(newCart);

    if (facebookPixel) {
      facebookPixel.trackEvent(
        'RemoveFromCart',
        {
          content_ids: [productId],
          content_type: 'product',
        }
      );
    }

    // Track RemoveFromCart Event
    // window.fbq("track", "RemoveFromCart", {
    //   content_ids: [productId],
    //   variant_id: [variantId],
    //   content_type: "product",
    //   currency: "PKR",
    // });
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  return (
    <>
      <UserContext.Provider value={{ user, setUser }}>
        <CartContext.Provider
          value={{
            cart,
            addToCart,
            removeFromCart,
            updateCart,
            setCart,
            clearCart,
          }}
        >
          <Navbar />
          <AllRouting />
          <Newsletter />
          <Footer />
          <ToastContainer />
        </CartContext.Provider>
      </UserContext.Provider>
    </>
  );
};

export default App;