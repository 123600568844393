// src/hooks/useFacebookPixel.js
import { useEffect, useState } from "react";
import { FacebookPixel } from "react-use-facebook-pixel";

const useFacebookPixel = () => {
  const [facebookPixel, setFacebookPixel] = useState(null);

  useEffect(() => {
    const initializeFacebookPixel = async () => {
      const pixel = new FacebookPixel({
        pixelID: "7028933373786534", // Replace with your actual Pixel ID
      });

      pixel.init({});

      setFacebookPixel(pixel);
    };

    initializeFacebookPixel();
  }, []);

  return facebookPixel;
};

export default useFacebookPixel;
